import React, { Component } from "react";
import Fade from "react-reveal";

class Footer extends Component {
  render() {
    if (!this.props.data) return null;

    const networks = this.props.data.social.map(function (network) {
      return (
        <li key={network.name}>
          <a href={network.url}>
            <i className={network.className}></i>
          </a>
        </li>
      );
    });

    const certificates = this.props.resume.certificates.map(function (certificate) {
      return (
        // <li key={certificate.certificate}>
          <a href={'https://www.credly.com/badges/' + certificate.credlybadgeid + '/public_url'}>
            <img src={'images/' + certificate.image} alt={certificate.certificate} width="120" height="120" />
          </a>
        // </li>
      );
    });
    
    const year = new Date().getFullYear();

    return (
      <footer>
        <div className="row">
          <Fade bottom>
            <div className="twelve columns">
              {certificates}
              <ul className="social-links">{networks}</ul>

              <ul className="copyright">
                <li>&copy; Copyright {year} JASONEND.CLOUD INC. </li>
                <li>
                  Design by{" "}
                  <a title="Styleshout" href="http://www.styleshout.com/">
                    Styleshout
                  </a>
                </li>
              </ul>
            </div>
          </Fade>

          <div id="go-top">
            <a className="smoothscroll" title="Back to Top" href="#home">
              <i className="icon-up-open"></i>
            </a>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
